import React, { useState } from 'react';
import { Drawer, Checkbox, Button } from 'antd';
import styles from './Notification.less';
import intl from 'react-intl-universal'

const CheckGroup = Checkbox.Group

function FilterNotification({ visible, hiddenFilter, handleConfirm }) {
    const [selectType,setSelectType] = useState([])

    const handleCheckbox = (data) => {
        setSelectType(data)
    }

    const handleClear = () => {
        setSelectType([])
    }

    return (
        <Drawer
            visible={visible}
            closable={false}
            className={styles.filterDrawer}
            width='395px'
            title={
                <div className={styles.header}><span>{intl.get('notification.filterTitle')}</span><i class="material-icons notranslate" onClick={hiddenFilter}>close</i></div>
            }
            onClose={hiddenFilter}
        >
            <div className={styles.bodyBox}>
                <CheckGroup className={styles.filterCheckbox} onChange={handleCheckbox} value={selectType}>
                    <Checkbox value={5}>{intl.get('notification.product_price_has_changed')}</Checkbox>
                    <Checkbox value={1}>{intl.get('notification.filter_variant_out_of_stock')}</Checkbox>
                    <Checkbox value={2}>{intl.get('product.filterBySku')}</Checkbox>
                    <Checkbox value={3}>{intl.get('product.filterByGone')}</Checkbox>
                    <Checkbox value={6}>{intl.get('notification.cancelOrder')}</Checkbox>
                </CheckGroup>
                <div className={styles.button}>
                    <Button onClick={handleClear}>{intl.get('notification.clearFilter')}</Button> <Button onClick={() => {
                        handleConfirm(selectType)
                        hiddenFilter()
                    }}>{intl.get('product.introduce_ok')}</Button>
                </div>
            </div>
        </Drawer>
    )
}

export default FilterNotification