import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Button, Modal, Select, Layout, List, message, Alert, Tooltip,Dropdown,Menu, Checkbox } from 'antd';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';

import FixedHeaderLayout from 'layouts/FixedHeaderLayout';
import { InfoAlert } from 'components/Banner/OptimizerInfoTip';
import OnlyPagination from 'components/OnlyPagination';
import Mapping from 'components/Mapping';
import PriceChange from './PriceChange';
import SkuChange from './SkuChange';
import FilterNotification from './FilterNotification';
import ResetPasswordModal from 'routes/User/ResetPasswordModal';
import RecommendTag from '../Order/component/RecommendTag';
import { getMsgByType, getShopifyProdItems, getShopifyProdItemsForStore } from './utils';

import { getAliExpressUrl } from '../../utils/utils';

import styles from './Notification.less';
import copy from 'assets/bargain/copy.png';
import checked from 'assets/bargain/check_circle.png';
import copyToClipboard from 'utils/copyToClipboard';

const { Option, OptGroup } = Select;
const { Content } = Layout;
const ButtonGroup = Button.Group;
const { confirm } = Modal;

const readStyle = { background: '#D6D6D6' };

const bannerTrickConfig = {
  'track-type': 'productBargain',
  'track-name': 'supplyBannerV4',
  'track-metadata-position': 'notification'
};

/**
 * 根据不同类型通知展示不同广告
 *
 * @param {*} type
 * @returns
 */
const getContentType = type => {
  let bannerType;
  if (type === 1 || type === 2 || type === 3) {
    bannerType = 1;
  } else if (type === 5) {
    bannerType = 2;
  }

  return bannerType;
};

const getBannerData = (list, bannerData) => {
  if (!list || !bannerData) {
    return {};
  }

  const result = {};

  // eslint-disable-next-line
  for (const item of list) {
    const time = new Date(item.createdat);
    const current = new Date();
    current.setDate(current.getDate() - 1);

    // 大于 24 hours
    if (time < current) {
      continue;
    }

    const bannerType = getContentType(item.type);

    if (!bannerType) {
      continue;
    }

    // 最新的变更
    if (
      result[`${item.data.aliexpressprod?.productid}_${bannerType}`] &&
      time <
        new Date(
          result[`${item.data.aliexpressprod?.productid}_${bannerType}`].time
        )
    ) {
      continue;
    }

    const target = bannerData.find(
      i => i.ae_product_id === item.data.aliexpressprod?.productid
    );

    if (target) {
      result[`${target.ae_product_id}_${bannerType}`] = {
        ...target,
        bannerType,
        time: item.createdat
      };
    }
  }

  return result;
};

@connect(({ loading, global, login, user }) => ({
  listLoading: loading.effects['notification/list'],
  timezoneRegion: global.timezoneRegion,
  userInfo: login.userInfo,
  newUserInfo:user.newUserInfo
}))
export default class Notification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showProductChange: false, // 显示商品变动弹窗
      notificationList: [], // 所有消息数据
      ProductChangeData: {},
      showSkuChange: false, // 显示sku变换详情
      showMapping: false,
      supplyData: {}, // 供应链 banner 数据
      currentStores:'AllStores',
      searchStores:'all',
      checkedState:[],
      modalVisible:false,
      filterVisible:false,
      copySuccess:''
    };
    this.ref = React.createRef()
  }

  componentDidMount() {
    // 获取Notification未读数量
    this.props.dispatch({
      type: 'topBtn/notificationUnreadLength',
      payload: {
        cancelMessage: true
      }
    });
    this.getAllRates();
    this.notificationList();
  }

  toJSONLocal = (date, isDate) => {
    const { timezoneRegion } = this.props;
    let local = moment(date);
    if (timezoneRegion) {
      local = moment(date).tz(timezoneRegion);
    }
    if (isDate) {
      return local.format('YYYY-MM-DD');
    }
    return local.format('HH:mm');
  };

  // 设置翻页游标
  setCursor = (data = {}) => {
    const { cursor = '', notis = [] } = data;

    this.cursor[this.currentPage + 1] = cursor;
    this.listLength = notis?.length;
  };

  currentPage = 1;
  cursor = { 1: '' }; // 翻页游标
  listLength = 0;
  pageSize = 20;
  aeProd = null;
  selectType = []

  // 获取消息列表
  notificationList = () => {
    this.props.dispatch({
      type: 'notification/list',
      payload: {
        data: {
          cursor: this.cursor[this.currentPage],
          limit: this.pageSize,
          type: '',
          isread: '',
          store_ids:this.state.searchStores === 'all' 
          ? [] : [this.state.searchStores],
          notification_type:this.selectType,
        },
        callback: d => {
          if (d.code === 2000) {
            this.setCursor(d.data);
            this.count = d.data.count;
            const notis = new Map();
            d.data?.notis?.forEach(item => {
              const key = this.toJSONLocal(item.createdat, true);
              if (notis.has(key)) {
                notis.get(key).push(item);
                return;
              }
              notis.set(key, [item]);
            });

            let supplyData = {};

            // callback 在 event loop 中执行，如果报错会造成异常
            try {
              supplyData = getBannerData(d.data.notis, d.data.spread);
            } catch (error) {
              console.log('getBannerData error:', error);
            }
            this.setState({
              notificationList: [...notis],
              supplyData
            });
          }
        }
      }
    });
  };

  getRates = currency => {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: 'user/rates',
        payload: {
          data: {
            currency_code: currency
          },
          callback: d => {
            resolve(d);
          }
        }
      });
    });
  };

  getAllRates = async () => {
    const {
      userInfo: { stores = [] }
    } = this.props;

    const currencyMap = {};
    const storeCurrencyMap = {};

    for (let i = 0, len = stores.length; i < len; i++) {
      if (
        stores[i]?.ali_currency === undefined ||
        currencyMap[stores[i]?.ali_currency]
      ) {
        continue;
      }

      const res = await this.getRates(stores[i]?.ali_currency);

      if (res.code === 2000) {
        currencyMap[res.data.currency_code] = res.data;
      }
    }

    stores.forEach(item => {
      if (item?.ali_currency) {
        storeCurrencyMap[item.id] = currencyMap[item.ali_currency];
        return;
      }
      storeCurrencyMap[item?.id] = item;
    });

    this.props.dispatch({
      type: 'global/setCurrency',
      payload: storeCurrencyMap
    });
  };

  // 改变页码
  changePager = flag => {
    this.state.checkedState = []
    this.currentPage =
      flag == 'prev' ? this.currentPage - 1 : this.currentPage + 1;
    this.notificationList();
    this.goTop();
  };

  // 改变一页显示数量
  changePageSize = v => {
    this.pageSize = parseInt(v);
    this.currentPage = 1;
    this.cursor = { 1: '' };
    this.notificationList();
    this.goTop();
  };

  goTop = () => {
    const anchorElement = document.getElementById('notificationBox');
    if (anchorElement) {
      anchorElement.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  };

  // 获取ae商品详情
  openMapping = (id, prod) => {
    this.props.dispatch({
      type: 'notification/shopifyProdDetail',
      payload: {
        data: {
          id,
          store_id: this.getStoreId(id, prod)
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 && d.data) {
            this.showMapping(d.data);
            this.setOneIsRead(prod);
          } else if (d.code == 5000 && d.msg == 'store_id not exist') {
            message.error(intl.get('notification.store_not_available'));
          }
        }
      }
    });
  };

  // 获取shopify商品所在的store id
  getStoreId = (shopifyPordid, prod) => {
    const { shopify_prods = {} } = prod;
    let v = '';

    Object.entries(shopify_prods).forEach(arr => {
      arr[1].forEach(item => {
        if (item.productid == shopifyPordid) {
          v = arr[0];
        }
      });
    });

    return v;
  };

  onTopBtn = ref => {
    this.topbtn = ref;
  };

  reGetUnRead = () => {
    this.topbtn.getNotificationUnreadLength();
  };

  // 设置一条消息已读
  setOneIsRead = (li, type) => {
    if (li.isread) {
      if (type) {
        window.location.href = '/app/order/list?id=6';
      }
      return;
    }
    li.isread = true;

    this.props.dispatch({
      type: 'notification/update',
      payload: {
        data: {
          id: li.id,
          isread: true
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            // this.topbtn.getNotificationUnreadLength();
            if (type) {
              window.location.href = '/app/order/list?id=6';
            }
            // 更新 header 中的 notice
            this.props.dispatch({
              type: 'notification/list',
              payload: {
                fromMsg: true,
                data: {
                  cursor: '',
                  limit: 5,
                  type: '',
                  isread: ''
                }
              }
            });
          }
        }
      }
    });
  };

  // 标记选中的为已读
  handleMarkRead = () => {
    let checked = this.state.checkedState.join(',')
    this.state.notificationList.forEach(item=>{
      item[1].forEach(item2=>{
        if(this.state.checkedState.includes(item2.id)){
            item2.isread = true
        }
      })
    })
    this.props.dispatch({
      type: 'notification/update',
      payload: {
        data: {
          id: checked,
          isread: true
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              checkedState: []
            })
            // 更新 header 中的 notice
            this.props.dispatch({
              type: 'notification/list',
              payload: {
                fromMsg: true,
                data: {
                  cursor: '',
                  limit: 5,
                  type: '',
                  isread: ''
                }
              }
            });
          }
        }
      }
    });
  };

  // type == 99 只设置已读
  clickLi = items => {
    if (items.type != 99) {
      return;
    }
    this.setOneIsRead(items);
  };

  // 隐藏view弹窗
  hideProductChange = () => {
    this.setState({
      showProductChange: false
    });
  };

  hiddenFilterDrawer = () => {
    this.setState({
      filterVisible : false
    })
  }

  // 跳转到setting
  goSetting = () => {
    this.props.dispatch(routerRedux.push('/settings?id=10'));
  };


  // 跳转到Shopify
  goShopify = item => {
    const { shopify_prods = {} } = item;
    let storeId = '';
    let prodId = '';

    Object.entries(shopify_prods).forEach(prod => {
      const prods = prod[1];
      storeId = prod[0];
      prods.forEach(product => {
        prodId = product.productid;
      });
    });

    if (!storeId || !prodId) {
      return;
    }

    const stores = this.props.userInfo.stores || [];
    let domain = '';

    stores.forEach(item => {
      if (item.id == storeId) {
        domain = item.domain;
      }
    });

    if (!domain) {
      return;
    }

    let shopifyProductDetailUrl = '';
    shopifyProductDetailUrl = `https://${domain}/admin/products/${prodId}`; // shopify管理后台商品编辑页
    window.open(shopifyProductDetailUrl, '_blank');
  };

  // 跳转到order ------没用到
  goOrder = items => {
    window.location = '/app/order/list';
    localStorage.setItem('ProductSta', 4);
    this.items(items);
  };

  // 跳转到product
  goProduct = item => {
    const { shopify_prods } = item;
    let pid = '';

    Object.entries(shopify_prods).forEach(item => {
      const prods = item[1];

      prods.forEach(product => {
        pid = product.productid;
      });
    });

    localStorage.setItem('shopifyProductID', pid);

    this.props.dispatch(routerRedux.push('/')); // 首页
  };

  // 获取ae商品变化，影响到几个shopify商品
  getShopifyProdItems = item => {
    const { shopify_prods = {} } = item;
    const arr = [];

    Object.values(shopify_prods).forEach(item => {
      item.forEach(i => {
        i.productid && arr.push(i);
      });
    });
    return arr;
  };

  // 显示成本变化详情
  viewPrice = items => {
    this.setState({
      showProductChange: true,
      ProductChangeData: items
    });

    this.setOneIsRead(items);
  };

  // 显示sku变化详情
  showSkuChange = notificationItem => {
    this.setState({
      showSkuChange: true,
      ProductChangeData: notificationItem
    });
    this.setOneIsRead(notificationItem);
  };

  // 风控单跳转
  handleRiskControlSheet = (data, type) => {
    this.setOneIsRead(data, type);
  };

  // 隐藏sku变换详情
  hideSkuChange = () => {
    this.setState({
      showSkuChange: false
    });
  };

  // 下线商品
  putOffline = (id,data) => {
    if (!id) {
      return;
    }
    let storeid = ''
    for(let i in data.shopify_prods){
      data.shopify_prods[i].forEach(item=>{
        if(item.id === id){
          storeid = i
        }
      })
    }

    this.setOneIsRead(data);
    confirm({
      title: intl.get('notification.offline_product'),
      okText: intl.get('public.confirm'),
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.props.dispatch({
            type: 'productmy/productStatus',
            payload: {
              data: {
                product_id: id,
                is_pub: false,
                store_id:storeid
              },
              callback: d => {
                if (d.code == 2000 || d.code == 2010) {
                  message.success('Product is offline');
                }

                resolve();
              }
            }
          });
        }).catch(() => console.log('Oops errors!'));
      }
    });
  };

  // 阻止冒泡
  stopPropagation = e => {
    e.stopPropagation();
    return false;
  };

  handleCopy = (e,title,id)=>{
    if(this.ref.current){
      clearTimeout(this.ref.current)
    }
    this.stopPropagation(e)

    const text = title
    navigator.clipboard.writeText(text);

    this.setState({
      copySuccess:id
    })

   this.ref.current =  setTimeout(() => {
      this.setState({
        copySuccess:''
      })
    }, 1000);
}

  // 获取商品下线的操作按钮
  getOfflineBtns = data => {
    const arr = getShopifyProdItemsForStore(data,this.props.userInfo.stores);
    return (
      <div className={styles.offLineBtns}>
        <Select
          size="small"
          placeholder={intl.get('notification.mappingPlaceholder')}
          onSelect={v => this.openMapping(v, data)}
          className={styles.offLineMapping}
          dropdownClassName={styles.offLineDropdown}
        >
          {arr.map(prod => {
            return (
              <OptGroup key={Object.keys(prod)[0]} label={Object.keys(prod)[0]}>
                  {
                    Object.values(prod)[0].map(item => {
                      return (
                        <Option key={item.productid} value={item.productid}>{item.title} 
                        <span className={styles.copy} onClick={(e)=>{this.handleCopy(e,item.title,item.productid)}}>
                            <Tooltip title={this.state.copySuccess !== item.productid ? intl.get('notification.copyTitle') : intl.get('notification.copied')}>
                              {
                                this.state.copySuccess !== item.productid ? 
                                <img src={copy} alt="" /> :
                                <img src={checked} alt="" />
                              }
                            </Tooltip>
                          </span>                        
                        </Option>
                      )
                    })
                  }
              </OptGroup>
            );
          })}
        </Select>
        <Select
          size="small"
          placeholder={intl.get('notification.put_offline')}
          onSelect={v => this.putOffline(v,data)}
          className={styles.offLineSelect}
          dropdownClassName={styles.offLineDropdown}
        >
           {arr.map(prod => {
            return (
              <OptGroup key={Object.keys(prod)[0]} label={Object.keys(prod)[0]}>
                  {
                    Object.values(prod)[0].map(item => {
                      return (
                        <Option key={item.id} value={item.id}>{item.title}
                        <span className={styles.copy} onClick={(e)=>{this.handleCopy(e,item.title,item.productid)}}>
                            <Tooltip title={this.state.copySuccess !== item.productid ? intl.get('notification.copyTitle') : intl.get('notification.copied')}>
                              {
                                this.state.copySuccess !== item.productid ? 
                                <img src={copy} alt="" /> :
                                <img src={checked} alt="" />
                              }
                            </Tooltip>
                          </span>                            
                        </Option>
                      )
                    })
                  }
              </OptGroup>
            );
          })}
        </Select>
      </div>
    );
  };

  // 获取一条消息的操作按钮
  getNotificationBtns = data => {
    let btn = null;
    switch (data.type) {
      case 1:
        btn = (
          <Tooltip title={intl.get('notification.view_variant')}>
            <span>
              <i class="material-icons" style={{cursor: 'pointer'}} onClick={e => this.showSkuChange(data)}>visibility</i>
            </span>
          </Tooltip>
          
        );
        break;
      case 2:
        btn = 
        <div className={styles.listItemRight}>
          <a
            className={styles.listItemTxtP}
            href={getAliExpressUrl(
              data.data.aliexpressprod?.productid
            )}
            target="_blank"
            title={data.data.aliexpressprod?.title}
          >
            {data.data.aliexpressprod?.title}
          </a>
          {
            data.data.aliexpressprod?.title &&
            <span className={styles.copy} onClick={(e)=>{this.handleCopy(e,data.data.aliexpressprod?.title,data.data.aliexpressprod?.productid)}}>
            <Tooltip title={this.state.copySuccess !== data.data.aliexpressprod?.productid ? intl.get('notification.copyTitle') : intl.get('notification.copied')}>
              {
                this.state.copySuccess !== data.data.aliexpressprod?.productid ? 
                  <img src={copy} alt="" /> :
                  <img src={checked} alt="" />
              }
            </Tooltip>
          </span> 
          }           
          {this.getOfflineBtns(data)}
        </div>
        break;
      case 3:
        btn = 
        <div className={styles.listItemRight}>
          <a
            className={styles.listItemTxtP}
            href={getAliExpressUrl(
              data.data.aliexpressprod?.productid
            )}
            target="_blank"
            title={data.data.aliexpressprod?.title}
          >
            {data.data.aliexpressprod?.title}
          </a>
          {
            data.data.aliexpressprod?.title &&
            <span className={styles.copy} onClick={(e)=>{this.handleCopy(e,data.data.aliexpressprod?.title,data.data.aliexpressprod?.productid)}}>
            <Tooltip title={this.state.copySuccess !== data.data.aliexpressprod?.productid ? intl.get('notification.copyTitle') : intl.get('notification.copied')}>
              {
                this.state.copySuccess !== data.data.aliexpressprod?.productid ? 
                  <img src={copy} alt="" /> :
                  <img src={checked} alt="" />
              }
            </Tooltip>
          </span>  
          }
          {this.getOfflineBtns(data)}
        </div>
        break;
      case 5:
        btn = (
          <Tooltip title={intl.get('notification.view_price')}>
            <span>
              <i class="material-icons" style={{cursor: 'pointer'}} onClick={e => this.viewPrice(data)}>visibility</i>
            </span>
          </Tooltip>
        );
        break;
      case 6:
        btn = (
          <Tooltip title={intl.get('notification.view_canceled_orders')}>
            <span>
            <i class="material-icons" style={{cursor: 'pointer', position: 'relative'}} onClick={e => this.handleRiskControlSheet(data, true)}>visibility</i>
            </span>
          </Tooltip>
        );
        break;
      default:
    }
    return btn;
  };

  mappingShopifyProdData = null; // 打开mapping时传入的shopify商品数据
  fbdSupply = {};

  // 显示mapping
  showMapping = shopifyProdData => {
    if (shopifyProdData == '') {
      message.warning('Product deleted');
      return;
    }
    const { prod, supply } = shopifyProdData;

    const obj = {
      id: prod.id,
      image: prod.detail.image.src,
      title: prod.detail.title,
      prod
    };

    this.fbdSupply = supply;
    this.mappingShopifyProdData = obj;
    this.setState({
      showMapping: true
    });
  };

  // 隐藏mapping
  hideMapping = () => {
    this.setState({
      showMapping: false
    });
  };

  handleBannerClick = async data => {
    const { dispatch } = this.props;

    // Post('/prod/propool/spread/notification', { data: { id: data.id } }).catch(
    //   error => {
    //     console.log('fetch error:', error);
    //   }
    // );

    dispatch({
      type: 'global/showOneClickMapping',
      payload: data
    });
  };

 handleMenu = (item)=>{
   if(item.key === this.state.searchStores){
      return
   }
    if(item.key === 'all'){
      this.setState({
        searchStores:'all'
      },()=>{
        this.notificationList()
      })
    }else{
      this.setState({
        searchStores:item.key
      },()=>{
        this.notificationList()
      })
    }
  }
  
  handleCheckBox = (e) => {
      this.setState({
        checkedState:e
      })
  }

  handleSelectAll = (e) =>{
    let arr = []
    if(e.target.checked){
      this.state.notificationList.forEach(item=>{
        item[1].forEach(item2=>{
          arr.push(item2.id)
        })
      })
    }
    this.setState({
      checkedState : arr
    })
  }

  indeterminateStatus = () => {
    let arr = []
    this.state.notificationList.forEach(item=>{
      item[1].forEach(item2=>{
        arr.push(item2.id)
      })
    })
    if(this.state.checkedState.length && this.state.checkedState.length !== arr.length){
      return true
    }else{
      return false
    }
  }

  selectAllStatus = () => {
    let arr = []
    if(this.state.notificationList.length == 0){
      return false
    }
    this.state.notificationList.forEach(item=>{
      item[1].forEach(item2=>{
        arr.push(item2.id)
      })
    })
    if(this.state.checkedState.length !== arr.length){
      return false
    }else{
      return true
    }
  }

  handleDelete= () => {
    let checked = this.state.checkedState.join(',')
    this.state.notificationList.forEach(item=>{
      item[1].forEach(item2=>{
        if(this.state.checkedState.includes(item2.id)){
            
        }
      })
    })
    this.props.dispatch({
      type: 'notification/deleteProductNt',
      payload: {
        data: {
          ids: checked,
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              checkedState: []
            })
            // 更新 header 中的 notice
            this.props.dispatch({
              type: 'notification/list',
              payload: {
                fromMsg: true,
                data: {
                  cursor: '',
                  limit: 5,
                  type: '',
                  isread: ''
                }
              }
            });
            this.notificationList()
            this.setState({modalVisible:false})
          }
        }
      }
    });
  }


  storeListMenu = (
    <Menu onClick={this.handleMenu} className={styles.menu}>
      <Menu.Item
        value="all"
        key="all"
      >
        {intl.get('top.top_select_store')}
      </Menu.Item>
      {this.props.userInfo.stores.map(store => {
         return <Menu.Item key={store?.id}>
            <span
            >
              {store?.shop_name}
            </span>
          </Menu.Item>
      })}
    </Menu>
  );
  
  renderText = ()=>{
    if(this.state.searchStores === 'all'){
      return <span>{intl.get('top.top_select_store')}</span>;
    }
    const res = this.props.userInfo.stores.filter(i => i?.id === this.state.searchStores);
    if(res.length){
      return <span>{res[0]?.shop_name}</span>;
    }
  }

  renderTopStoreList = () => {
    return (
        <Dropdown
          overlay={this.storeListMenu}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          <span className={styles.notificationTopTextX}>
            <i class="material-icons notranslate">storefront</i>
            <span className={styles.topTextY}>
              {this.renderText()}
            </span>
            <i class="material-icons notranslate">arrow_drop_down</i>
          </span>
      </Dropdown>
    )
  }

  handleConfirm = (selectData) => {
    this.selectType = selectData
    this.notificationList()
  }

  render() {
    const { listLoading } = this.props;

    const {
      showProductChange,
      showSkuChange,
      notificationList,
      supplyData,
      ProductChangeData,
      showMapping,
      currentStores,
      modalVisible,
      filterVisible
    } = this.state;
    return (
      <FixedHeaderLayout extraContent={this.renderTopStoreList()}>
        <Content style={{ padding: '0', marginTop: 30 }}>
          <div className={styles.notificationBox} id="notificationBox">
            <InfoAlert className={styles.tip} text={intl.get('notification.tip')}/>
            <div className={styles.notification}>
              <div className={styles.notificationTitle}>
                <h3>{intl.get('notification.notifications')}</h3>
                <div>
                  <div onClick={()=>{this.setState({filterVisible:true})}}>
                    <i class="material-icons">filter_alt</i>
                    {intl.get('notification.filter')}
                  </div>
                  <div onClick={this.goSetting}>
                    <i class="material-icons">settings</i>
                    {intl.get('guidance.setting')}
                  </div>
                </div>
              </div>

              <div className={styles.notificationBtn}>
                <Checkbox onChange={this.handleSelectAll} checked={this.selectAllStatus()} indeterminate={this.indeterminateStatus()}>{intl.get('product.selectAll')}</Checkbox>
              <div className={styles.otherFeature}>
                  <div onClick={this.state.checkedState.length ? this.handleMarkRead : null} style={this.state.checkedState.length ? null : {cursor:'not-allowed',color:'rgba(0, 0, 0, 0.24)'}}>
                    <i style={this.state.checkedState.length ? null : {cursor:'not-allowed'}} class="material-icons">assignment</i>
                    {intl.get('notification.mark_all_as_read')}
                  </div>
                  <div onClick={this.state.checkedState.length ? ()=>{this.setState({modalVisible:true})} : null} style={this.state.checkedState.length ? null : {cursor:'not-allowed',color:'rgba(0, 0, 0, 0.24)'}}>
                    <i  style={this.state.checkedState.length ? null : {cursor:'not-allowed'}} class="material-icons notranslate">delete</i>
                    {intl.get('public.delete')}
                  </div>
              </div>
              </div>
              <Checkbox.Group onChange={this.handleCheckBox} value={this.state.checkedState}>
              <List
                loading={listLoading}
                itemLayout="vertical"
                className={styles.notificationList}
                dataSource={notificationList}
                renderItem={([key, value]) => (
                  <List.Item>
                    <h4>{key}</h4>
                    <ol>
                      {value.map((item, index) => {
                        const bannerType = getContentType(item.type);
                        const target =
                          supplyData[
                            `${item.data?.aliexpressprod?.productid}_${bannerType}`
                          ];

                        if (item.type == 4) {
                          return null;
                        }
                        return (
                          <li
                            style={
                              item.type == 99 ? { cursor: 'pointer' } : null
                            }
                            key={`${item.id}_${index}`}
                            className={styles.listItemCont}
                            onClick={() => this.clickLi(item)}
                          >
                            <div className={styles.notificationContent}>
                              {/* 点 */}
                              <Checkbox value={item.id}></Checkbox>
                              <span
                                className={styles.redSpot}
                                style={item.isread ? readStyle : null}
                              />
                              {/* 时间 */}
                              <span className={styles.itemTime}>
                                {this.toJSONLocal(item.createdat)}
                              </span>
                              {/* 标题 */}
                              <p className={styles.prodChangeTitle}>
                                {item.type !== 6
                                  ? getMsgByType(item.type)
                                  : `${intl.get(
                                      'notification.focus_on_orders.p1'
                                    )}${
                                      item.data?.ae_order_cancel_num
                                        ? item.data?.ae_order_cancel_num
                                        : 0
                                    } ${intl.get(
                                      'notification.focus_on_orders.p2'
                                    )}`}
                                {item.type !== 6 ? (
                                  <p className={styles.shopifyProductLength}>
                                    {getShopifyProdItems(item).length}{' '}
                                    {intl.get(
                                      'notification.related_shopify_products_length'
                                    )}
                                  </p>
                                ) : null}
                                </p>
                              {/* 跳转AE商品订单 */}
                              <p>
                                {item.data?.ae_order_cancel_ids && item.data?.ae_order_cancel_ids?.length > 1 && (
                                    <Select
                                      size="small"
                                      placeholder="select an order"
                                      className={styles.orderSelect}
                                      defaultValue={
                                        <span
                                          style={{ color: '#1890ff' }}
                                        >{`${intl.get('order.search_type.ali_order_no')}:${item.data?.ae_order_cancel_ids[0]}`}</span>
                                      }
                                    >
                                      {item.data?.ae_order_cancel_ids.map(
                                        (i, t) => {
                                          return (
                                            <Option key={t}>
                                              <a
                                                href={`https://trade.aliexpress.com/order_detail.htm?orderId=${i}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: '#1890ff' }}
                                              >{`${intl.get('order.search_type.ali_order_no')}:${i}`}</a>
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  )}
                                {item.data?.ae_order_cancel_ids &&
                                  item.data?.ae_order_cancel_ids?.length == 1 && (
                                    <a
                                      href={`https://trade.aliexpress.com/order_detail.htm?orderId=${item.data.ae_order_cancel_ids[0]}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: '#1890ff',display:'inline-block',width:'333px'  }}
                                    >{`${intl.get('order.search_type.ali_order_no')}:${item.data.ae_order_cancel_ids[0]}`}</a>
                                  )}
                              </p>
                              {/* 跳转AE商品详情 */}
                              {item.type !== 2 && item.type !==3 
                              ? 
                                item.type !== 6 ? (
                                  <div className={styles.listItemTxtPBox}>
                                  <a
                                    className={styles.listItemTxtP}
                                    href={getAliExpressUrl(
                                      item.data.aliexpressprod?.productid
                                    )}
                                    target="_blank"
                                    title={item.data.aliexpressprod?.title}
                                  >
                                    {item.data.aliexpressprod?.title}
                                  </a>
                                  {
                                    item.data.aliexpressprod?.title ? 
                                    <span className={styles.copy} onClick={(e)=>{this.handleCopy(e,item.data.aliexpressprod?.title,item.data.aliexpressprod?.productid)}}>
                                      <Tooltip title={this.state.copySuccess !== item.data.aliexpressprod?.productid ? intl.get('notification.copyTitle') : intl.get('notification.copied')}>
                                        {
                                          this.state.copySuccess !== item.data.aliexpressprod?.productid ? 
                                            <img src={copy} alt="" /> :
                                            <img src={checked} alt="" />
                                        }
                                      </Tooltip>
                                  </span>  
                                  :
                                  null
                                  }
                                  </div>
                                ) : (
                                  <div className={styles.listItemTxtP} />
                                ) 
                              : null
                              }
                              {this.getNotificationBtns(item)}
                            </div>

                            
                            {target && target.time === item.createdat ? (
                              <div className={styles.supplyBanner}>
                                <RecommendTag
                                  data={target}
                                  title={
                                    target.bannerType === 1
                                      ? intl.get('bargain.alternative_stock')
                                      : intl.get('bargain.alternative_price')
                                  }
                                  onLinkClick={this.handleBannerClick}
                                  showPoint
                                  trickConfig={bannerTrickConfig}
                                />
                              </div>
                            ) : null}
                          </li>
                        );
                      })}
                    </ol>
                  </List.Item>
                )}
              />
              </Checkbox.Group>
              <OnlyPagination
                len={this.listLength}
                pageSize={this.pageSize}
                currentPage={this.currentPage}
                changePageSize={this.changePageSize}
                changePager={this.changePager}
                count={this.count}
              />
            </div>
          </div>
        </Content>
        {/* head重置密码 弹层 */}
        <ResetPasswordModal />
        {/* 确认删除弹窗 */}
        <Modal 
            title={intl.get('notification.modalTitle')}
            visible={modalVisible}
            wrapClassName={styles.modalStyle}
            onCancel={()=>{this.setState({modalVisible:false})}}
            footer={
              <Button
                style={{minWidth:"95px",height:'36px',background:'#FF8F00',borderRadius:'8px',color:'#FFFFFF'}}
                onClick={this.handleDelete}
              >
                {intl.get('public.confirm')}
              </Button>
            }
            >
             <p>{intl.get('notification.modalText')} </p>
          </Modal>

        {/* view price 弹层 */}
        <PriceChange
          notificationItem={ProductChangeData}
          visible={showProductChange}
          handleCancel={this.hideProductChange}
        />

        <SkuChange
          notificationItem={ProductChangeData}
          visible={showSkuChange}
          handleCancel={this.hideSkuChange}
          showMapping={this.showMapping}
        />

        <Mapping
          visible={showMapping}
          originProductData={this.mappingShopifyProdData}
          hideSupplier={this.hideMapping}
          fbdSupply={this.fbdSupply}
        />
        <FilterNotification 
          visible={filterVisible}
          hiddenFilter = {this.hiddenFilterDrawer}
          handleConfirm = {this.handleConfirm}
        />
      </FixedHeaderLayout>
    );
  }
}
